const sidebarHamburger = document.querySelector('.hamburger');
const sidebar = document.querySelector('.sidebar');
const fullHeader = document.querySelector('.dynamic-page');

if(sidebarHamburger){
  function minimiseSidebar() {
    sidebar.style.width = '100px';
    sidebar.classList.add('minimise');
    fullHeader.classList.add('full-header');
    sidebarHamburger.classList.add('sidebar-close');
  }

  sidebarHamburger.addEventListener('click', e => {
    if (sidebar.style.width == '') {
      minimiseSidebar();
      localStorage.setItem('minimise', 'true');
    } else {
      sidebar.style.width = '';
      sidebar.classList.remove('minimise');
      fullHeader.classList.remove('full-header');
      sidebarHamburger.classList.remove('sidebar-close');
      localStorage.setItem('minimise', 'false');
    }
    e.preventDefault();
  });

  if (localStorage.getItem('minimise') === 'true') {
    minimiseSidebar();
  }
}
