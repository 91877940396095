import Moment from 'moment';
import Pikaday from 'pikaday';
import './modules/alerts';
import './modules/sidebar';
import './modules/boxed_panels';
import './modules/faqs';
import './modules/back_to_top';
import './modules/fancy_select';
import './modules/fonts_observer';

const picker = new Pikaday({
  field: document.getElementById('datepicker'),
  format: 'DD MM YYYY',
  minDate: new Date(),
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

const picker2 = new Pikaday({
  field: document.getElementById('datepicker2'),
  format: 'DD MM YYYY',
  minDate: new Date(),
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

const picker3 = new Pikaday({
  field: document.getElementById('datepicker3'),
  format: 'DD MM YYYY',
  minDate: new Date(2016, 6, 20),
  maxDate: new Date(),
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

const picker4 = new Pikaday({
  field: document.getElementById('datepicker4'),
  format: 'DD MM YYYY',
  minDate: new Date(2016, 6, 20),
  maxDate: new Date(),
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

const picker5 = new Pikaday({
  field: document.getElementById('datepicker5'),
  format: 'DD MM YYYY',
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

const picker6 = new Pikaday({
  field: document.getElementById('datepicker6'),
  format: 'DD MM YYYY',
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});

Moment().format();
picker.getMoment();
picker2.getMoment();
picker3.getMoment();
picker4.getMoment();
picker5.getMoment();
picker6.getMoment();