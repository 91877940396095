const faqPanels = [...document.querySelectorAll('.faq')];

if(faqPanels){
  faqPanels.forEach(panel => {
    panel.querySelector('.open-faqs').addEventListener('click', (e) => {
      e.preventDefault();
      const faqAnswer = panel.querySelector('.faq-panel');
      faqAnswer.classList.toggle('open-faq');
    });
  })
}
