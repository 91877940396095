document.addEventListener('DOMContentLoaded', function() {
  'use strict';

  var goTopBtn = document.querySelector('.back-to-top-button');

  function trackScroll() {
    var scrolled = window.pageYOffset;
    var coords = document.documentElement.clientHeight;    

    if (scrolled > coords) {
      goTopBtn.classList.add('back-to-top-button--show');
    }
    if (scrolled < coords) {
      goTopBtn.classList.remove('back-to-top-button--show');
    }
  }

  function backToTop() {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -100);
      setTimeout(backToTop, 0);
    }
  }  

  
  if(goTopBtn){
    goTopBtn.addEventListener('click', backToTop);
    window.addEventListener('scroll', trackScroll);
  }
});
