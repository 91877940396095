import FontFaceObserver from 'fontfaceobserver';

const Gotham = new FontFaceObserver('Gotham');

Promise.all([Gotham.load()])
  .then(() => {
    document.body.classList.add('Gotham-loaded');
  })
  .catch(err => {
    document.body.classList.add('Gotham-failed');
  });
