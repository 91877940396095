const boxedPanels = [...document.querySelectorAll('.boxed-panel')];

if(boxedPanels){
  boxedPanels.forEach(panel => {
    panel.querySelector('.close-downloads').addEventListener('click', () => {
      const panelDownloads = panel.querySelector('.boxed-panel-downloads');
      const openDownloads = panel.querySelector('.open-downloads');
      panelDownloads.style.visibility = 'hidden';
      panelDownloads.style.height = '0';
      openDownloads.style.display = 'block';
    });
  });

  boxedPanels.forEach(panel => {
    panel.querySelector('.open-downloads').addEventListener('click', () => {
      const panelDownloads = panel.querySelector('.boxed-panel-downloads');
      const openDownloads = panel.querySelector('.open-downloads');
      panelDownloads.style.visibility = 'visible';
      panelDownloads.style.height = 'auto';
      openDownloads.style.display = 'none';
    });
  });
}
