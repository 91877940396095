const closeAlert = document.querySelector('.close');
const flashMessage = document.querySelector('.flash-message-container');


if(closeAlert){
  closeAlert.addEventListener('click', e => {
    flashMessage.style.display = 'none';
    e.preventDefault();
  })
}
