import Selectr from 'mobius1-selectr';

document.addEventListener('DOMContentLoaded', function() {
  if(document.querySelector('.selecting')){
    new Selectr('.selecting', {
      searchable: true,
      clearable: true,
      defaultSelected: true,
      allowDeselect: true,
      multiple: false,
    });
  }

  if(document.querySelector('.selecting-2')){
    new Selectr('.selecting-2', {
      searchable: true,
      clearable: true,
      defaultSelected: true,
      allowDeselect: true,
      multiple: false,
    });
  }
});
